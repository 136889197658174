<template>
    <b-modal title="Add new" v-model="editModalActive" no-close-on-backdrop size="xl">
        <template #default>
            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Primary text:
                            <tooltip-with-image img="/img/home_promotions/primary.png"/>
                        </label>
                        <b-form-input
                            type="text"
                            placeholder="Primary text"
                            v-model="editObject.primary_text"
                        />
                    </b-form-group>
                    <b-form-group>
                        <label>Secondary text:
                            <tooltip-with-image img="/img/home_promotions/secondary.png"/>
                        </label>
                        <b-form-input
                            type="text"
                            placeholder="Secondary text"
                            v-model="editObject.secondary_text"
                        />
                    </b-form-group>
                    <b-form-group>
                        <label>Connected item:</label>
                        <v-select
                            v-model="editObject.connected_item_id"
                            :reduce="item => item.id"
                            :filter="(items, search) => items.filter(item => item.name.toLowerCase().includes(search.toLowerCase()) || item.base_item_name.toLowerCase().includes(search.toLowerCase()))"
                            :options="items"
                        >
                            <template slot="option" slot-scope="item">
                                <span>{{ item.name + ' - ' + item.base_item_name }}</span>
                            </template>

                            <template slot="selected-option" slot-scope="item">
                                <span>{{ item.name + ' - ' + item.base_item_name }}</span>
                            </template>
                        </v-select>
                    </b-form-group>
                    <b-form-group>
                        <label>Start time:</label>
                        <flat-pickr
                            v-model="editObject.start_time"
                            class="form-control"
                            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true}"
                        />
                    </b-form-group>
                    <b-form-group>
                        <label>End time:</label>
                        <flat-pickr
                            v-model="editObject.end_time"
                            class="form-control"
                            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true}"
                        />
                    </b-form-group>
                    <b-form-group>
                        <label>Image:
                            <tooltip-with-image img="/img/home_promotions/image.png"/>
                        </label>
                        <ImageSelector v-model="editObject.image"/>
                    </b-form-group>
                </b-col>
                <b-col>

                    <b-form-group>
                        <label>Position:
                            <tooltip-with-image img="/img/home_promotions/position.png"/>
                        </label>
                        <div :style="{
                            position: 'relative',
                            width: moveScreenWidth + 'px',
                            height: moveScreenHeight + 'px',
                            border: '1px solid #1b2337'
                        }">
                            <div ref="positionDivEdit" id="positionDivEdit" :style="{
                                position: 'absolute',
                                cursor: 'move',
                                zIndex: 9,
                                backgroundColor: '#007bff',
                                width: promotionWidth + 'px',
                                height: promotionHeight + 'px',
                                top: editObject.design_config.top_offset * (moveScreenHeight - promotionHeight) + 'px',
                                left: editObject.design_config.left_offset * (moveScreenWidth - promotionWidth) + 'px'
                            }">

                            </div>
                        </div>
                    </b-form-group>

                    <hr/>

                    <b-form-group>
                        <label>Primary text color:
                            <tooltip-with-image img="/img/home_promotions/primary.png" cid="2"/>
                        </label>
                        <ColorWithOpacitySelector v-model="editObject.design_config.primary_text_color"/>
                    </b-form-group>

                    <b-form-group>
                        <label>Secondary text color:
                            <tooltip-with-image img="/img/home_promotions/secondary.png" cid="2"/>
                        </label>
                        <ColorWithOpacitySelector v-model="editObject.design_config.secondary_text_color"/>
                    </b-form-group>

                    <hr/>
                    <b-form-group>
                        <label>Background:
                            <tooltip-with-image img="/img/home_promotions/background.png"/>
                        </label>
                        <BackgroundSelector v-model="editObject.design_config.background"/>
                    </b-form-group>

                </b-col>
            </b-row>

            <hr/>

            <Translations class="mt-3" :base-url="`/api/management/v1/home_screen_promotion/${  editObject.id}`"
                          :fields="[{name: 'Primary Text', field: 'primary_text', type: 0}, {name: 'Secondary Text', field: 'secondary_text', type: 0}]"
                          v-on:translationsChanged="$emit('itemChanged')"/>

        </template>
        <template #modal-footer>

            <b-button variant="danger" @click="editModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="warning" @click="savePromotion">
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                <span class="align-middle">Save</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import {BButton, BCol, BFormGroup, BFormInput, BModal, BRow} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import flatPickr from 'vue-flatpickr-component'
    import Translations from '@/views/Translation/Translations'
    import ColorWithOpacitySelector from '@/views/components/ColorWithOpacitySelector'
    import BackgroundSelector from '@/views/components/BackgroundSelector'
    import ImageSelector from '@/views/components/ImageSelector'
    import TooltipWithImage from '@/views/components/TooltipWithImage'

    export default {
        components: {
            TooltipWithImage,
            ImageSelector,
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            Translations,
            BRow,
            BCol,
            ColorWithOpacitySelector,
            BackgroundSelector,
            flatPickr,
            vSelect
        },
        props: {
            items: {
                type: Array,
                required: true
            }
        },
        data() {
            return {

                editObject: {
                    design_config: {
                        primary_text_color: {},
                        secondary_text_color: {},
                        background: {},
                        top_offset: 0,
                        left_offset: 0
                    }
                },
                editModalActive: false,

                moveScreenWidth: 380,
                moveScreenHeight: 216,
                promotionWidth: 90,
                promotionHeight: 30

            }
        },
        methods: {
            open(item) {
                const baseObject = {
                    design_config: {
                        primary_text_color: {},
                        secondary_text_color: {},
                        background: {},
                        top_offset: 0,
                        left_offset: 0
                    }
                }
                this.editObject = {...baseObject, ...JSON.parse(JSON.stringify(item))}
                if (!this.editObject.design_config) this.editObject.design_config = baseObject.design_config
                this.editModalActive = true
                this.enableElementDrag()
            },
            savePromotion() {
                const thisIns = this
                const loadPromise = this.$http.put(`/api/management/v1/home_screen_promotion/${this.editObject.id}`, {
                    primary_text: this.editObject.primary_text,
                    secondary_text: this.editObject.secondary_text,
                    connected_item_id: this.editObject.connected_item_id,
                    start_time: this.moment(this.editObject.start_time).format(),
                    end_time: this.moment(this.editObject.end_time).format(),
                    image: this.editObject.image,
                    design_config: this.editObject.design_config
                })
                loadPromise.then(function() {
                    thisIns.$printSuccess('Home screen promotion saved')
                    thisIns.editModalActive = false
                    thisIns.editObject = {
                        design_config: {
                            primary_text_color: {},
                            secondary_text_color: {},
                            background: {},
                            top_offset: 0,
                            left_offset: 0
                        }
                    }
                    thisIns.$emit('itemChanged')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            },
            enableElementDrag() {
                let posLeftDelta = 0, posTopDelta = 0, lastX = 0, lastY = 0
                const thisIns = this

                this.$nextTick(function() {

                    const elmnt = thisIns.$refs.positionDivEdit
                    elmnt.onmousedown = (e) => {
                        e = e || window.event
                        e.preventDefault()
                        // get the mouse cursor position at startup:
                        lastX = e.clientX
                        lastY = e.clientY
                        document.onmouseup = () => {
                            document.onmouseup = null
                            document.onmousemove = null
                        }
                        // call a function whenever the cursor moves:
                        document.onmousemove = (e) => {
                            e = e || window.event
                            e.preventDefault()
                            // calculate the new cursor position:
                            posLeftDelta = lastX - e.clientX
                            posTopDelta = lastY - e.clientY
                            lastX = e.clientX
                            lastY = e.clientY
                            // set the element's new position:

                            const offsetTop = elmnt.offsetTop - posTopDelta
                            const offsetLeft = elmnt.offsetLeft - posLeftDelta

                            if (offsetTop >= 0 && offsetTop < (thisIns.moveScreenHeight - thisIns.promotionHeight - 1)) {
                                thisIns.editObject.design_config.top_offset = (offsetTop / (thisIns.moveScreenHeight - thisIns.promotionHeight)).toFixed(4)
                            }

                            if (offsetLeft >= 0 && offsetLeft < (thisIns.moveScreenWidth - thisIns.promotionWidth - 1)) {
                                thisIns.editObject.design_config.left_offset = (offsetLeft / (thisIns.moveScreenWidth - thisIns.promotionWidth)).toFixed(4)
                            }
                        }
                    }

                })
            }
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
