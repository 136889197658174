<template>
    <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop size="xl">
        <template #default>
            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Primary text: <tooltip-with-image img="/img/home_promotions/primary.png"/></label>
                        <b-form-input
                            type="text"
                            placeholder="Primary text"
                            v-model="addObject.primary_text"
                        />
                    </b-form-group>
                    <b-form-group>
                        <label>Secondary text: <tooltip-with-image img="/img/home_promotions/secondary.png"/></label>
                        <b-form-input
                            type="text"
                            placeholder="Secondary text"
                            v-model="addObject.secondary_text"
                        />
                    </b-form-group>
                    <b-form-group>
                        <label>Connected item:</label>
                        <v-select
                            v-model="addObject.connected_item_id"
                            :reduce="item => item.id"
                            :filter="(items, search) => items.filter(item => item.name.toLowerCase().includes(search.toLowerCase()) || item.base_item_name.toLowerCase().includes(search.toLowerCase()))"
                            :options="items"
                        >
                            <template slot="option" slot-scope="item">
                                <span>{{item.name + ' - ' + item.base_item_name}}</span>
                            </template>

                            <template slot="selected-option" slot-scope="item">
                                <span>{{item.name + ' - ' + item.base_item_name}}</span>
                            </template>
                        </v-select>
                    </b-form-group>
                    <b-form-group>
                        <label>Start time:</label>
                        <flat-pickr
                            v-model="addObject.start_time"
                            class="form-control"
                            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true}"
                        />
                    </b-form-group>
                    <b-form-group>
                        <label>End time:</label>
                        <flat-pickr
                            v-model="addObject.end_time"
                            class="form-control"
                            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true}"
                        />
                    </b-form-group>
                    <b-form-group>
                        <label>Image: <tooltip-with-image img="/img/home_promotions/image.png"/></label>
                        <ImageSelector v-model="addObject.image"/>
                    </b-form-group>
                </b-col>

                <b-col>

                    <b-form-group>
                        <label>Position: <tooltip-with-image img="/img/home_promotions/position.png"/></label>
                        <div :style="{
                            position: 'relative',
                            width: moveScreenWidth + 'px',
                            height: moveScreenHeight + 'px',
                            border: '1px solid #1b2337'
                        }" >
                            <div ref="positionDivAdd" id="positionDivAdd" :style="{
                                position: 'absolute',
                                cursor: 'move',
                                zIndex: 9,
                                backgroundColor: '#007bff',
                                width: promotionWidth + 'px',
                                height: promotionHeight + 'px',
                                top: addObject.design_config.top_offset * (moveScreenHeight - promotionHeight) + 'px',
                                left: addObject.design_config.left_offset * (moveScreenWidth - promotionWidth) + 'px'
                            }">

                            </div>
                        </div>
                    </b-form-group>

                    <hr/>

                    <b-form-group>
                        <label>Primary text color: <tooltip-with-image img="/img/home_promotions/primary.png" cid="2"/></label>
                        <ColorWithOpacitySelector v-model="addObject.design_config.primary_text_color"/>
                    </b-form-group>

                    <b-form-group>
                        <label>Secondary text color: <tooltip-with-image img="/img/home_promotions/secondary.png" cid="2"/></label>
                        <ColorWithOpacitySelector v-model="addObject.design_config.secondary_text_color"/>
                    </b-form-group>

                    <hr/>
                    <b-form-group>
                        <label>Background: <tooltip-with-image img="/img/home_promotions/background.png" cid="2" /></label>
                        <BackgroundSelector v-model="addObject.design_config.background"/>
                    </b-form-group>

                </b-col>
            </b-row>
        </template>
        <template #modal-footer>

            <b-button variant="danger" @click="addModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="primary" @click="addPromotion">
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Add</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import {BButton, BFormGroup, BFormInput, BModal, BRow, BCol} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import flatPickr from 'vue-flatpickr-component'
    import ColorWithOpacitySelector from '@/views/components/ColorWithOpacitySelector'
    import BackgroundSelector from '@/views/components/BackgroundSelector'
    import ImageSelector from '@/views/components/ImageSelector'
    import TooltipWithImage from '@/views/components/TooltipWithImage'

    export default {
        components: {
            TooltipWithImage,
            ImageSelector,
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            BRow,
            BCol,
            ColorWithOpacitySelector,
            BackgroundSelector,
            flatPickr,
            vSelect
        },
        props: {
            items: {
                type: Array,
                required: true
            }
        },
        data() {
            return {

                addObject: {
                    design_config: {
                        primary_text_color: {},
                        secondary_text_color: {},
                        background: {},
                        top_offset: 0,
                        left_offset: 0
                    }
                },
                addModalActive: false,

                moveScreenWidth: 380,
                moveScreenHeight: 216,
                promotionWidth: 90,
                promotionHeight: 30

            }
        },
        methods: {
            open() {
                this.addModalActive = true
            },
            addPromotion() {
                const thisIns = this
                const loadPromise = this.$http.post('/api/management/v1/home_screen_promotion', {
                    primary_text: this.addObject.primary_text,
                    secondary_text: this.addObject.secondary_text,
                    connected_item_id: this.addObject.connected_item_id,
                    start_time: this.moment(this.addObject.start_time).format(),
                    end_time: this.moment(this.addObject.end_time).format(),
                    image: this.addObject.image,
                    design_config: this.addObject.design_config
                })
                loadPromise.then(function() {
                    thisIns.$printSuccess('Home screen promotion added')
                    thisIns.addModalActive = false
                    thisIns.addObject = {
                        design_config: {
                            primary_text_color: {},
                            secondary_text_color: {},
                            background: {},
                            top_offset: 0,
                            left_offset: 0
                        }
                    }
                    thisIns.$emit('itemAdded')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            },
            enableElementDrag() {
                let posLeftDelta = 0, posTopDelta = 0, lastX = 0, lastY = 0
                const thisIns = this

                this.$nextTick(function() {

                    const elmnt = thisIns.$refs.positionDivAdd
                    elmnt.onmousedown = (e) => {
                        e = e || window.event
                        e.preventDefault()
                        // get the mouse cursor position at startup:
                        lastX = e.clientX
                        lastY = e.clientY
                        document.onmouseup = () => {
                            document.onmouseup = null
                            document.onmousemove = null
                        }
                        // call a function whenever the cursor moves:
                        document.onmousemove = (e) => {
                            e = e || window.event
                            e.preventDefault()
                            // calculate the new cursor position:
                            posLeftDelta = lastX - e.clientX
                            posTopDelta = lastY - e.clientY
                            lastX = e.clientX
                            lastY = e.clientY
                            // set the element's new position:

                            const offsetTop = elmnt.offsetTop - posTopDelta
                            const offsetLeft = elmnt.offsetLeft - posLeftDelta

                            if (offsetTop >= 0 && offsetTop < (thisIns.moveScreenHeight - thisIns.promotionHeight - 1)) {
                                thisIns.addObject.design_config.top_offset = (offsetTop / (thisIns.moveScreenHeight - thisIns.promotionHeight)).toFixed(4)
                            }

                            if (offsetLeft >= 0 && offsetLeft < (thisIns.moveScreenWidth - thisIns.promotionWidth - 1)) {
                                thisIns.addObject.design_config.left_offset = (offsetLeft / (thisIns.moveScreenWidth - thisIns.promotionWidth)).toFixed(4)
                            }
                        }
                    }

                })
            }
        },
        watch: {
            addModalActive() {
                if (this.addModalActive) this.enableElementDrag()
            }
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
