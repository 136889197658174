<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>

                <b-button variant="primary" @click="$refs.addHomeScreenPromotionModal.open()">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="promotions" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center" style="min-width: 150px">
                        <img :src="'/api/management/v1/image/' + props.row.image" style="max-width: 100%; max-height: 100px;" class="rounded-lg" alt="Image"/>
                    </div>
                    <span v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <b-badge v-if="props.formattedRow[props.column.field] === 'Yes'" variant="light-success">
                            Yes
                        </b-badge>
                        <b-badge v-else variant="light-danger">
                            No
                        </b-badge>
                    </span>
                    <div v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">

                        <b-button variant="warning" class="mr-1" @click="$refs.editHomeScreenPromotionModal.open(props.row)">
                            <feather-icon
                                icon="EditIcon"
                            />
                        </b-button>

                        <b-button variant="danger" @click="removePromotion(props.row.id)">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                        </b-button>
                    </div>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>
        
        <AddModal ref="addHomeScreenPromotionModal" :items="items" v-on:itemAdded="loadData"/>

        <EditModal ref="editHomeScreenPromotionModal" :items="items" v-on:itemChanged="loadData"/>
    </div>
</template>
<script>

    import {BBadge, BButton, BCard, BOverlay} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import AddModal from '@/views/HomeScreenPromotion/AddModal'
    import EditModal from '@/views/HomeScreenPromotion/EditModal'

    export default {
        components: {
            EditModal,
            AddModal,
            BOverlay,
            BCard,
            BButton,
            BasicTable,
            BBadge
        },
        data() {
            return {

                dataLoaded: false,
                promotions: [],
                items: [],

                columns: [
                    {
                        label: 'Image',
                        displayType: 1,
                        field: 'image',
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Connected item',
                        displayType: 0,
                        field: 'connected_item_id',
                        formatFn: (item) => (this.items.find(singleItem => singleItem.id === item) || {name: 'Unknown', base_item_name: 'Unknown'}).name,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Primary text',
                        displayType: 0,
                        field: 'primary_text',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Start time',
                        displayType: 0,
                        field: 'start_time',
                        formatFn: (time) => this.moment(time).format('YYYY/MM/DD HH:mm'),
                        filterOptions: {
                            filterFn: (data, filter) => this.moment(data).format('YYYY/MM/DD HH:mm').includes(filter),
                            enabled: true
                        }
                    },
                    {
                        label: 'End time',
                        displayType: 0,
                        field: 'end_time',
                        formatFn: (time) => this.moment(time).format('YYYY/MM/DD HH:mm'),
                        filterOptions: {
                            filterFn: (data, filter) => this.moment(data).format('YYYY/MM/DD HH:mm').includes(filter),
                            enabled: true
                        }
                    },
                    {
                        label: 'Fully translated',
                        displayType: 2,
                        field(rowObj) {
                            return (rowObj.fully_translated) ? 'Yes' : 'No'
                        },
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Yes / No'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 3,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/home_screen_promotion')
                loadPromise.then(function(response) {
                    thisIns.promotions = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                const itemsPromise = this.$http.get('/api/management/v1/items')
                itemsPromise.then(function(response) {
                    thisIns.items = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise, itemsPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            removePromotion(id) {

                this.dataLoaded = false
                const thisIns = this

                const removePromise = this.$http.delete(`/api/management/v1/home_screen_promotion/${  id}`)
                removePromise.then(function() {
                    thisIns.$printSuccess('Home screen promotion removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })

            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>